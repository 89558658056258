<template>
  <div class="order-detail-contaner">
    <Header></Header>
    <Nav></Nav>
    <Layout>
      <el-row class="pb16" type="flex" align="middle" justify="space-between">
        <div class="left f22 c3">
          订单详情
          <span class="blue" v-if="detailMsg.orderStatus == 3">（待派单）</span>
          <span class="blue" v-if="detailMsg.orderStatus == 0">（待处理）</span>
          <span class="blue" v-if="detailMsg.orderStatus == 1">（已退回）</span>
          <span class="blue" v-if="detailMsg.orderStatus == 2">（待分配）</span>
          <span class="blue" v-if="detailMsg.orderStatus == 4">（已派单）</span>
          <span class="blue" v-if="detailMsg.orderStatus == 5">（待验收）</span>
          <span class="blue" v-if="detailMsg.orderStatus == 6">（待评价）</span>
          <span class="black" v-if="detailMsg.orderStatus == 7"
            >（已完成）</span
          >
          <span class="blue" v-if="detailMsg.orderStatus == 99"
            >（已取消）</span
          >
        </div>
        <div class="right">
          <button @click="toDevice">
            <img src="@/assets/images/home/return-img.png" alt="" />
          </button>
        </div>
      </el-row>
      <div class="line"></div>
      <!-- 订单详情start -->
      <div class="order-box mt32">
        <!-- 维修工程师 -->
        <div
          class="item mb24"
          v-if="
            detailMsg.orderStatus == 4 ||
            detailMsg.orderStatus == 5 ||
            detailMsg.orderStatus == 6 ||
            detailMsg.orderStatus == 7
          "
        >
          <p class="line1 f18 c3">维修工程师</p>
          <p
            class="line2 mt20"
            v-for="(item, index) in detailMsg.workOrderVOS"
            :key="index"
          >
            <span class="f14 c3 mr32"
              >主负责工程师：<span class="f14 c6">{{
                item.currentEngineerName ? item.currentEngineerName : "-"
              }}</span></span
            >
            <span class="f14 c3 mr32"
              >联系方式：<span class="f14 c6">{{
                item.engineerMobile ? item.engineerMobile : "-"
              }}</span></span
            >
            <span class="f14 c3 mr32"
              >协助工程师：<span class="f14 c6">{{
                item.viceEngineerName ? item.viceEngineerName : "-"
              }}</span></span
            >
          </p>
        </div>
        <!-- 维修地址 -->
        <div
          class="item mb24"
          v-if="
            detailMsg.orderStatus == 0 ||
            detailMsg.orderStatus == 3 ||
            detailMsg.orderStatus == 2 ||
            detailMsg.orderStatus == 4 ||
            detailMsg.orderStatus == 5 ||
            detailMsg.orderStatus == 6 ||
            detailMsg.orderStatus == 7 ||
            detailMsg.orderStatus == 99 ||
            detailMsg.orderStatus == 1
          "
        >
          <p class="line1 f18 c3">维修地址</p>
          <p class="line2 mt20">
            <span class="f14 c3 mr32"
              >用户名称：<span class="f14 c6">{{
                detailMsg.contactsName
              }}</span></span
            >
            <!-- <span class="f14 c3 mr32"
              >用户名称：<span class="f14 c6"
                >{{ detailMsg.contactsName }}（{{
                  addMsg.sex == 1 ? "先生" : "女士"
                }}）</span
              ></span
            > -->
            <!-- <span class="f14 c3 mr32"
              >联系方式：<span class="f14 c6">{{
                addMsg.contactsPhone
              }}</span></span
            > -->
            <span class="f14 c3 mr32"
              >联系方式：<span class="f14 c6">{{
                detailMsg.contactsPhone
              }}</span></span
            >
            <!-- <span class="f14 c3 mr32"
              >用户地址：<span class="f14 c6">{{
                addMsg.provinceName +
                " " +
                addMsg.cityName +
                " " +
                addMsg.areaName +
                " " +
                addMsg.address +
                " "
              }}</span></span
            > -->
            <span class="f14 c3 mr32"
              >用户地址：<span class="f14 c6">{{
                detailMsg.address
              }}</span></span
            >
          </p>
        </div>
        <!--设备详情 -->
        <div
          class="item mb24"
          v-if="
            detailMsg.orderStatus == 0 ||
            detailMsg.orderStatus == 3 ||
            detailMsg.orderStatus == 2 ||
            detailMsg.orderStatus == 4 ||
            detailMsg.orderStatus == 5 ||
            detailMsg.orderStatus == 6 ||
            detailMsg.orderStatus == 7 ||
            detailMsg.orderStatus == 99 ||
            detailMsg.orderStatus == 1
          "
        >
          <p class="line1 f18 c3">设备详情</p>
          <p class="line2 mt20">
            <span class="f14 c3 mr32"
              >设备型号：<span class="f14 c6">{{
                detailMsg.deviceModel ? detailMsg.deviceModel : "-"
              }}</span></span
            >
            <span class="f14 c3 mr32"
              >设备品牌：<span class="f14 c6">{{
                detailMsg.brandName ? detailMsg.brandName : "-"
              }}</span></span
            >
            <span class="f14 c3 mr32"
              >公司名称：<span class="f14 c6">{{
                detailMsg.companyName ? detailMsg.companyName : "-"
              }}</span></span
            >
            <span class="f14 c3 mr32"
              >选择设备类型：<span class="f14 c6">{{
                detailMsg.typeName ? detailMsg.typeName : "-"
              }}</span></span
            >
          </p>
          <p class="line2 mt20">
            <span class="f14 c3 mr32"
              >控制系统：<span class="f14 c6">{{
                detailMsg.controlName ? detailMsg.controlName : "-"
              }}</span></span
            >
          </p>
        </div>
        <!--维保详情 -->
        <div
          class="item mb24"
          v-if="
            detailMsg.orderStatus == 0 ||
            detailMsg.orderStatus == 3 ||
            detailMsg.orderStatus == 2 ||
            detailMsg.orderStatus == 4 ||
            detailMsg.orderStatus == 5 ||
            detailMsg.orderStatus == 6 ||
            detailMsg.orderStatus == 7 ||
            detailMsg.orderStatus == 99 ||
            detailMsg.orderStatus == 1
          "
        >
          <p class="line1 f18 c3">维保详情</p>
          <p class="line2 mt20">
            <span class="f14 c3 mr32"
              >检测内容：<span class="f14 c6">{{
                detailMsg.repairContent
              }}</span></span
            >
          </p>
          <el-row class="imgs mt20" type="flex">
            <template v-if="imgs.length > 0">
              <p class="f14 c3">相关图片:</p>
              <div class="img-box">
                <!-- detailMsg.imgsUrl -->
                <img
                  v-for="(item, index) in imgs"
                  :key="index"
                  :src="item"
                  alt=""
                />
              </div>
            </template>
            <template v-if="video">
              <p class="f14 c3">相关视频:</p>
              <div class="img-box">
                <!-- detailMsg.imgsUrl -->
                <!-- <img
                  v-for="(item, index) in imgs"
                  :key="index"
                  :src="item"
                  alt=""
                /> -->
                <a :href="video" target="_blank">
                  <video
                    :src="video"
                    width="94px"
                    height="94px
                "
                  ></video>
                </a>
              </div>
            </template>
          </el-row>
        </div>

        <!--订单详情 -->
        <div
          class="item mb24"
          v-if="
            detailMsg.orderStatus == 0 ||
            detailMsg.orderStatus == 3 ||
            detailMsg.orderStatus == 2 ||
            detailMsg.orderStatus == 4 ||
            detailMsg.orderStatus == 5 ||
            detailMsg.orderStatus == 6 ||
            detailMsg.orderStatus == 7 ||
            detailMsg.orderStatus == 99 ||
            detailMsg.orderStatus == 1
          "
        >
          <p class="line1 f18 c3">订单详情</p>
          <p class="line2 mt20">
            <span class="f14 c9"
              >订单编号<span class="f14 c3 ml20">{{
                detailMsg.orderNum
              }}</span></span
            >
          </p>
          <p class="line2 mt20">
            <span class="f14 c9"
              >下单时间<span class="f14 c3 ml20">{{
                detailMsg.createTime
              }}</span></span
            >
          </p>
        </div>
        <!--评价信息 -->
        <div class="item mb24" v-if="detailMsg.orderStatus == 7">
          <p class="line1 f18 c3">评价信息</p>
          <p class="line2 mt20">
            <span class="f14 c3 mr32"
              >评价内容：<span class="f14 c6">
                <el-rate
                  v-model="detailMsg.evaluateGrade"
                  disabled
                  :colors="colors"
                  void-color="#E6E6E6"
                  text-color="#ff9900"
                >
                </el-rate></span
            ></span>
            <span class="f14 c3 mr32"
              >评价时间：<span class="f14 c6">{{
                detailMsg.evaluateTime
              }}</span></span
            >
          </p>
          <p class="line2 mt20">
            <span class="f14 c3 mr32"
              >评价内容：<span class="f14 c6">{{
                detailMsg.evaluateContent
              }}</span></span
            >
          </p>
          <el-row class="imgs mt20" type="flex">
            <p class="f14 c3">相关图片:</p>
            <div class="img-box">
              <!-- detailMsg.evaluateImgs -->
              <img
                v-for="(item, index) in detailMsg.evaluateImgs.split(',')"
                :key="index"
                :src="item"
                alt=""
              />
              <!-- <img src="@/assets/images/home/jichuang.png" alt="" /> -->
            </div>
          </el-row>
        </div>
        <!--订单取消 -->
        <div class="item mb24" v-if="detailMsg.orderStatus == 99">
          <p class="line1 f18 c3">订单取消</p>
          <p class="line2 mt20">
            <span class="f14 c9"
              >取消原因<span class="f14 c3 ml20">{{
                cancelMsg.name
              }}</span></span
            >
          </p>
          <p class="line2 mt20">
            <span class="f14 c9"
              >详细原因<span class="f14 c3 ml20">{{
                detailMsg.cancelExplain
              }}</span></span
            >
          </p>
        </div>
        <button
          v-if="
            detailMsg.orderStatus == 3 ||
            detailMsg.orderStatus == 2 ||
            detailMsg.orderStatus == 1
          "
          class="btn-cancle"
          @click="assderVisible = true"
        >
          取消订单
        </button>
        <button
          v-if="detailMsg.orderStatus == 6"
          class="btn-cancle"
          @click="toComment"
        >
          去评价
        </button>
      </div>
      <!-- 订单详情end -->
    </Layout>
    <Footer></Footer>
    <!-- 弹窗 start -->
    <el-dialog
      :visible.sync="assderVisible"
      width="750px"
      :show-close="false"
      class="assderTc"
    >
      <span slot="title" class="title">
        <p class="fs18 font-33">取消订单</p>
        <img
          src="@/assets/images/home/close.png"
          @click="assderVisible = false"
          class="hand"
        />
      </span>

      <div>
        <!-- 表单开始 -->
        <el-form
          :model="ruleForm"
          :status-icon="false"
          :rules="rules"
          ref="ruleForm"
          class="demo-ruleForm"
          :hide-required-asterisk="true"
        >
          <!--联系地址： -->
          <el-form-item>
            <p class="f14 c3">选择取消原因：</p>
            <div
              class="item"
              v-for="(item, index) in cancleListArr"
              :key="index"
              @click="changeSex(index)"
            >
              <img
                v-if="ruleForm.reason == index"
                src="@/assets/images/home/correct.png"
                alt=""
              />
              <img src="@/assets/images/home/no-correct.png" v-else alt="" />
              <span>{{ item.name }}</span>
            </div>
          </el-form-item>

          <!-- 联系地址：结束 -->
          <!-- 详细地址: -->
          <el-form-item required prop="details">
            <p class="f14 c3 detail-contant">请输入详细原因：</p>
            <el-input
              type="textarea"
              v-model="ruleForm.details"
              placeholder="请输入详细原因"
              autocomplete="off"
            ></el-input>
          </el-form-item>
          <!-- 详细地址:结束 -->
          <!-- 详细地址: -->
          <el-form-item class="btn">
            <button class="cancle" @click.prevent="cancle">取消</button>
            <button class="submit" @click.prevent="submitForm('ruleForm')">
              确认提交
            </button>
          </el-form-item>
          <!-- 详细地址:结束 -->
        </el-form>
        <!-- 表单结束 -->
      </div>
    </el-dialog>
    <!-- 弹窗结束 -->

    <tell-us transform="translateY(-50%)" top="50%"></tell-us>
  </div>
</template>

<script>
import Layout from "@/components/Layout.vue";
import Header from "@/components/home/Header.vue";
import Nav from "@/components/home/Nav.vue";
import Footer from "@/components/home/Footer.vue";
import TellUs from "@/components/TellUs.vue";
export default {
  name: "YoufujichuangOrderDetail",
  components: {
    Layout,
    Header,
    Nav,
    Footer,
    TellUs,
  },
  data() {
    var validateDetails = (rule, value, callback) => {
      if (value === "") {
        callback(new Error("请输入详细原因"));
      }
      callback();
    };
    return {
      assderVisible: false, //客服弹窗
      value: 3.7, //评级
      colors: { 2: "#FF0015" },
      ruleForm: {
        reason: 0,
        details: "",
      },
      rules: { details: [{ validator: validateDetails, trigger: "blur" }] },

      id: "", //id

      detailMsg: {}, //设备详细信息

      // cancleList: [],

      addId: "", //地址id

      addMsg: {}, //地址信息

      cancelId: ",//取消原因id",
      cancelMsg: {}, //取消信息

      cancleListArr: [],

      imgs: [],

      video: "",
    };
  },

  mounted() {},

  created() {
    this.id = this.$route.query.id;
    // 报修订单-通过id查询
    this.repairOrderById();

    // 取消原因-分页列表查询
    this.cancleList();
  },

  methods: {
    // 取消原因-分页列表查询
    cancleList() {
      this.$API
        .cancleList()
        .then((res) => {
          if (res.code == 1) {
            this.cancleListArr = res.data;
          } else {
            this.$message.info(res.msg);
          }
        })
        .catch((err) => {
          console.log(err);
        });
    },

    // 去评价
    toComment() {
      this.$router.push({
        name: "comment",
        query: {
          id: this.id,
        },
      });
    },

    // 根据id查询取消原因
    cancleResonById() {
      let paramData = {
        id: Number(this.cancelId),
      };
      this.$API
        .cancleResonById(paramData)
        .then((res) => {
          if (res.code == 1) {
            this.cancelMsg = res.data;
          } else {
            this.$message.info(res.msg);
          }
        })
        .catch((err) => {
          console.log(err);
        });
    },
    // 根据id查询某个地址
    getOnePos() {
      let paramData = {
        id: this.addId,
      };
      this.$API
        .getOnePos(paramData)
        .then((res) => {
          if (res.code == 1) {
            this.addMsg = res.data;
          } else {
            this.$message.info(res.msg);
          }
        })
        .catch((err) => {
          console.log(err);
        });
    },
    // 报修订单-通过id查询
    repairOrderById() {
      let paramData = {
        id: this.id,
      };
      this.$API
        .repairOrderById(paramData)
        .then((res) => {
          if (res.code == 1) {
            this.cancelId = res.data.cancelId;
            this.addId = res.data.orderAddrId;
            // console.log("地址id", this.addId);
            this.detailMsg = res.data;
            // 图片或者视频的处理   videoUrl
            if (this.detailMsg.imgsUrl) {
              this.imgs = this.detailMsg.imgsUrl.split(",");
            }
            if (this.detailMsg.videoUrl) {
              this.video = this.detailMsg.videoUrl;
            }
            // this.detailMsg.orderStatus = 9;
            // 根据id查询某个地址;
            this.getOnePos();
            if (this.detailMsg.orderStatus == 99) {
              // 根据id查询取消原因
              this.cancleResonById();
            }
          } else {
            this.$message.info(res.msg);
          }
        })
        .catch((err) => {
          console.log(err);
        });
    },
    // 取消
    cancle() {
      this.ruleForm.reason = 0;
      this.ruleForm.details = "";
      this.assderVisible = false;
    },
    // 提交
    submitForm(formName) {
      this.$refs[formName].validate((valid) => {
        if (valid) {
          let paramData = {
            cancelId: this.cancleListArr[this.ruleForm.reason].id, //取消id
            cancelExplain: this.ruleForm.details, //取消说明
            id: Number(this.id),
            orderStatus: 99,
          };
          console.log(paramData, "paramdata");
          this.$alert("确定要取消吗？", "提示", {
            confirmButtonText: "确定",
            cancelButtonText: "取消",
            showCancelButton: true,
            callback: (action) => {
              // 确定  confirm  取消 cancel
              if (action == "confirm") {
                this.$API
                  .cancleRepairOrder(paramData)
                  .then((res) => {
                    if (res.code == 1) {
                      this.$message({
                        message: "取消成功",
                        type: "success",
                      });
                      this.assderVisible = false;
                      setTimeout(() => {
                        this.$router.back();
                      }, 500);
                    } else {
                      this.$message.info(res.msg);
                    }
                  })
                  .catch((err) => {
                    console.log(err);
                  });
              }
            },
          });

          // alert("submit!");
        } else {
          // console.log("error submit!!");
          // return false;
        }
      });
    },
    // 改变性别
    changeSex(index) {
      this.ruleForm.reason = index;
    },
    //弹窗方法
    assderHandle() {
      this.assderVisible = false;
    },
    // 返回
    toDevice() {
      this.$router.push("/contact/order");
    },
  },
};
</script>

<style lang="scss">
.el-dialog {
  padding-bottom: 15px !important;
}
.order-detail-contaner {
  .assderTc .title {
    padding-top: 32px;
    display: flex;
    justify-content: center;
    align-items: center;
    position: relative;
    font-size: 22px;
    font-weight: bold;
    color: #333333;
  }
  .assderTc .title img {
    width: 32px;
    height: 32px;
    position: absolute;
    top: 32px;
    right: 32px;
  }
  .btn-cancle {
    width: 214px;
    height: 50px;
    background: linear-gradient(45deg, #ed1b23 0%, #f15f64 100%);
    border-radius: 8px 8px 8px 8px;
    color: #ffffff;
    margin-top: 48px;
    margin-left: 461px;
  }
  .el-rate__icon {
    color: #ff0015;
  }
  .el-rate {
    display: inline-block;
  }
  .blue {
    color: #007bd9;
  }
  .right {
    button {
      //   width: 96px;
      //   height: 40px;
      //   background: #efefef;
      //   border-radius: 4px 4px 4px 4px;
      img {
        width: 72px;
        height: 24px;
      }
    }
  }
  //   订单详情
  .order-box {
    .item {
      width: 1000px;
      min-height: 107px;
      box-sizing: border-box;
      padding: 24px;
      background: #ffffff;
      border-radius: 8px 8px 8px 8px;
      border: 1px solid #999999;
    }
    .img-box {
      margin-left: 20px;
      img {
        width: 94px;
        height: 94px;
        margin-right: 10px;
        border-radius: 8px;
      }
    }
  }
  // 自定义弹窗样式
  .assderTc {
    // display: flex;
    // align-items: center;
    // .title {
    //   padding-top: 32px;
    //   display: flex;
    //   justify-content: center;
    //   align-items: center;
    //   position: relative;
    //   font-size: 22px;
    //   font-weight: bold;
    //   color: #333333;
    //   img {
    //     width: 32px;
    //     height: 32px;
    //     position: absolute;
    //     top: 32px;
    //     right: 32px;
    //   }
    // }
    // ::v-deep .el-dialog__header {
    //   padding: 0;
    // }
    // ::v-deep .el-dialog {
    //   border-radius: 0 !important;
    // }
    // ::v-deep .el-dialog__body {
    //   padding: 0;
    // }
    // ::v-deep .el-dialog__footer {
    //   padding: 0;
    // }
    // ::v-deep .el-dialog {
    //   border-radius: 10px;
    // }
    // .ja-c {
    // }

    // .btnad {
    //   width: 112px;
    //   height: 40px;
    //   background: rgba(246, 247, 249, 1);
    //   border: 1px solid #ecedef;
    //   opacity: 1;
    //   border-radius: 20px;
    // }
    // .btnsad {
    //   color: #333;
    //   width: 112px;
    //   height: 40px;
    //   background: rgba(255, 195, 44, 1);
    //   opacity: 1;
    //   border-radius: 20px;
    // }
  }
  .el-form {
    padding: 0px !important;
    padding-bottom: 20px;
  }
  .el-form-item__content {
    line-height: normal;
  }

  .el-dialog__body {
    padding: 0px;
  }
  .el-dialog__header {
    padding: 0px;
  }
  .el-form-item {
    margin-bottom: 0px;
  }
  .demo-ruleForm {
    margin-top: 16px;
    margin-left: 32px;
    margin-bottom: 36px;
    // 性別
    .item {
      margin-top: 24px;
      // display: inline-block;
      margin-left: 20px;
      cursor: pointer;
      color: #333333;
      font-size: 14px;
      img {
        width: 16px;
        height: 16px;
        vertical-align: middle;
        margin-right: 5px;
      }
    }
    .detail-contant {
      margin-top: 30px;
      margin-bottom: 16px;
    }
  }
  .el-textarea__inner {
    width: 686px;
    height: 128px;
    background: #ffffff;
    border-radius: 8px 8px 8px 8px;
    border: 1px solid #999999;
  }
  .btn {
    margin-bottom: 20px;
    margin-top: 56px;
    .cancle {
      width: 214px;
      height: 50px;
      background: rgba(255, 0, 21, 0.13);
      border-radius: 8px 8px 8px 8px;
      border: 1px solid #ff0e1e;
      margin-left: 105px;
      color: #ff0015;
      font-size: 16px;
    }
    .submit {
      width: 214px;
      height: 50px;
      background: linear-gradient(45deg, #ed1b23 0%, #f15f64 100%);
      border-radius: 8px 8px 8px 8px;
      margin-left: 48px;
      color: #ffffff;
      font-size: 16px;
    }
  }
}
</style>